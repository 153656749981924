import React from "react"
import { GiBread, GiSandsOfTime } from "react-icons/all"


class Receta extends React.Component {
  render() {
    const { receta } = this.props

    function showTablaReceta(receta, name) {
      if (receta !== undefined && receta.length > 0) {
        return (
          <section>
            <h2 className="text-center text-xl font-black mt-5 mb-0">{name}</h2>
            <div className="overflow-x-auto">
              <table className="table-auto bg-gray-200 text-sm border border-gray mt-8 w-full">
                <thead>
                <tr>
                  <th>Ingrediente</th>
                  <th>Cantidad</th>
                  <th>Porcentaje</th>
                  <th>Comentario</th>
                </tr>
                </thead>
                <tbody>
                {receta.map(({ Ingrediente, Cantidad, Porcentaje, Comentario }) => {
                  return (
                    <tr key={Ingrediente}>
                      <td>{Ingrediente}</td>
                      <td>{Cantidad}</td>
                      <td>{Porcentaje}</td>
                      <td>{Comentario}</td>
                    </tr>
                  )
                })}
                </tbody>
              </table>
            </div>
          </section>
        )
      }
    }

    function showTablaItinerario(itineriario) {
      if (itineriario !== undefined && itineriario.length > 0) {
        return (
          <section>
            <h2 className="text-center text-xl font-black mt-5 mb-0">Itinerario</h2>
            <div className="flex flex-col mx-5 md:mx-20">
              <span className="text-5xl mx-auto mt-3"><GiSandsOfTime/></span>
              {itineriario.map(({ Tiempo, Accion }) => {
                return (
                  <div className="my-5 flex flex-wrap">
                    {Accion ?
                      <input type="checkbox" className="check w-3/12" name="step" aria-labelledby="heading1 step1"
                             aria-describedby="desc1"/> : <div className="hidden"></div>}
                    <span
                      className={Accion ? "ml-3 text-sm text-black w-7/12 mx-auto " : "w-full border-b  text-center text-2xl"}
                      id="step1">{Tiempo}</span>
                    <p className="ml-10 step w-full  text-base" id="desc1">
                      <span className="w-full">{Accion}</span>
                    </p>
                  </div>
                )
              })}
              <span className="text-6xl mx-auto"><GiBread/></span>
            </div>
          </section>
        )
      }
    }

    if (receta === null) {
      return null
    } else {
      return (
        <article className="mb-10">
          {showTablaItinerario(receta.itinerario)}

          <p className="mt-10 text-center text-sm"><b>Aclaración:</b><i>En general propongo más MM de la necesaria en la
            primer tabla porque calculo que me alcance para otras recetas o para guardar en la heladera. Para esta
            receta solo necesitás la cantidad de MM que dice en la segunda tabla.</i></p>
          {showTablaReceta(receta.pre_receta, "Masa Madre")}
          {showTablaReceta(receta.receta, "Masa Final")}
          <p><b>Fuente de la receta:</b> <a target="_blank" href={receta.fuente.link}>{receta.fuente.texto}</a></p>
        </article>
      )
    }
  }
}

export default Receta

import React from "react"
import PropTypes from "prop-types"
import { FiFacebook, FiTwitter } from "react-icons/fi"
import { FaWhatsapp } from "react-icons/fa"
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from "react-share"

const Share = ({ socialConfig, tags }) => (
  <div className="w-100">
    <div className="flex justify-around flex-wrap mx-auto my-10">
      <span className="mx-auto mb-5 w-full text-center">Compartí el pan:</span>
      <div className="flex justify-around w-2/6 lg:w-1/6 text-lg">
        <FacebookShareButton url={socialConfig.config.url}>
			<span className="icon">
				<FiFacebook/>
			</span>
        </FacebookShareButton>
        <TwitterShareButton url={socialConfig.config.url}
                            title={socialConfig.config.title} via={socialConfig.twitterHandle.split("@").join("")}
                            hashtags={tags}>
			<span className="icon">
				<FiTwitter/>
			</span>
        </TwitterShareButton>
        <WhatsappShareButton url={socialConfig.config.url}
                             title={socialConfig.config.title}>
			<span className="icon">
				<FaWhatsapp/>
			</span>
        </WhatsappShareButton>
      </div>
    </div>
  </div>
)

Share.propTypes = {
  socialConfig: PropTypes.shape({
    twitterHandle: PropTypes.string.isRequired,
    config: PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  }).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
}
Share.defaultProps = {
  tags: [],
}

export default Share
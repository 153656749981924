import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Receta from "../components/receta"
import Img from "gatsby-image"
import { FaTemperatureLow } from "react-icons/fa"
import Share from "../components/share"


function BlogPostTemplate(props) {


  const post = props.data.markdownRemark
  const siteTitle = props.data.site.siteMetadata.title
  const { previous, next } = props.pageContext
  const featuredImgFluid = post.frontmatter.foto.childImageSharp.fluid
  const twitterHandle = props.data.site.siteMetadata.social.twitter

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={post.frontmatter.foto.childImageSharp.resize.src}
        url={props.location.href}
      />
      <article>
        <header>
          <h1 className="text-5xl font-black mt-8 mb-3">
            {post.frontmatter.title}
          </h1>
          <div className="flex items-center text-sm mb-8">
            <p className="leading-loose">{post.frontmatter.date}</p>
            <span className="w-3"/>
            {post.frontmatter.temperatura &&
            <p className="flex items-center"><FaTemperatureLow/> {post.frontmatter.temperatura} </p>}
            <span className="w-3"/>
            {post.frontmatter.receta && <span><b>Tipo: </b>{post.frontmatter.receta.tipo}</span>}
          </div>
        </header>
        <Img className="mb-10 mt-10" fluid={featuredImgFluid}/>

        <section
          className="markdown"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />

        <Receta receta={post.frontmatter.receta}/>
        <Share socialConfig={{
          config: { title: post.frontmatter.title, url: props.location.href },
          twitterHandle: twitterHandle,
        }}/>
        <hr className="h-px mb-8"/>
        <footer>
          <Bio/>
        </footer>
      </article>

      <nav>
        <ul className="flex flex-wrap justify-between mb-8">
          <li>
            {previous && (
              <Link
                className="text-blue-600"
                to={previous.fields.slug}
                rel="prev"
              >
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link
                className="text-blue-600"
                to={next.fields.slug}
                rel="next"
              >
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`

  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        social {
          twitter
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "DD/MM/YYYY")
        temperatura
        receta {
            id
            tipo
            title
            fuente {
              texto
              link
            }
            date
            pre_receta {
              Ingrediente
              Cantidad
              Porcentaje
              Comentario
            }
            receta {
              Ingrediente
              Cantidad
              Porcentaje
              Comentario
            }
            itinerario {
              Tiempo
              Accion
            }
          }
        foto {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
            resize(width: 900, quality: 90) {
              src
            }
          }
        }
      }
    }
  }
`
